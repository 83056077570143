<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                    class="mt-1"
                    btnTitle="Go Back"
                    title="Update Journal"
                    @onClickCloseButton="goToList"
                />
                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label class="col-form-label">Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="journal.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label class="col-form-label">Business</label>
                        <select name="" id="business" class="form-control flatpickr-input invoice-edit-input" v-model="journal.business_id">
                            <option selected>Select One</option>
                            <option v-for="business in businesses" :value="business.id">{{business.name}}</option>
                        </select>
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label class="col-form-label">Party</label>
                        <select name="" id="business" class="form-control flatpickr-input invoice-edit-input" v-model="journal.contact_profile_id">
                            <option selected>Select One</option>
                            <option v-for="contactProfile in contactProfiles" :value="contactProfile.id">{{contactProfile.name}}</option>
                        </select>
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label class="col-form-label">Voucher No</label>
                        <div
                            class="input-group input-group-merge invoice-edit-input-group has-validation"
                            :class="isEmptyVoucher ? 'is-invalid' : ''"
                        >
                            <div class="input-group-text">
                                <span :class="isEmptyVoucher ? 'text-danger' : ''">{{ journal.voucher_prefix }}-</span>
                            </div>
                            <input
                                type="text"
                                class="form-control invoice-edit-input"
                                aria-describedby="validationVoucherNameFeedback"
                                placeholder=""
                                v-model="journal.voucher_serial"
                                :class="isEmptyVoucher ? 'is-invalid' : ''"
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-2 mt-3">
                <AddFormElement
                    class="mb-2"
                    :accountHeads="accountHeads"
                    v-for="(item, index) in journal.ledgers"
                    :key="index"
                    :index="index"
                    :item="item"
                    @onClose="onClose"
                />

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
                    <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="journal.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="mt-2">
                    <button
                        :disabled="!isDebitCreditEqual || loading || isEmptyVoucher"
                        @click="saveJournal"
                        class="btn btn-primary me-1"
                    >
                        <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Update
                    </button>
                    <button
                        @click="goToList"
                        class="btn btn-danger"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <VoucherValidationModal
            ref="voucherValidationModal"
            :voucherQuery="voucherQuery"
            @on-press-ok="setVoucherNumberAndDate(true)"
        />
    </div>
</template>

<script>
import handleJournal from '@/services/modules/journal'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import { inject } from 'vue'
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/journal/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import {editVoucherNumber, generateQuery, generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";

export default {
    name: 'JournalEdit',
    components: {
        VoucherValidationModal,
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        isEmptyVoucher: false,
        journal: {
            company_id:'',
            contact_profile_id: null,
            business_id: null,
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_type: 'journal_voucher',
            voucher_prefix : '',
            voucher_serial : '',
            voucher_no : '',
            ledgers: [
                {
                    account_head_id: 10,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                }
            ]
        }
    }),
    computed: {
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.journal.ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total.toFixed(2)
        },
        totalCredit () {
            let total = 0;
            this.general.map(i => {
                if(i.credit) total += i.credit
            })
            return total.toFixed(2)
        },
        isDebitCreditEqual () {
            return this.totalCredit === this.totalDebit
        },
        voucherQuery () {
            return generateQuery(this.$route.params.companyId, 'journal_voucher', 'general_journal')
        }
    },
    methods: {
        concatVoucher(){
            this.isEmptyVoucher = false;

            if(! this.journal.voucher_serial) {
                this.isEmptyVoucher = true;
                return ;
            }

            let voucherSerial = this.decimalFormat(this.journal.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
            if(voucherSerial === '') {
                this.isEmptyVoucher = true;
            }
            this.journal.voucher_serial = voucherSerial;
            this.journal.voucher_no = `${this.journal.voucher_prefix}-${voucherSerial}`;
        },
        
        async getAccountHeads() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {

                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        async getJournal() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchJournal(this.$route.params.journalId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.journal = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        async getContactProfiles() {
          try {
            let companyId = this.$route.params.companyId
            let contactId = '';
            let q = '';
            let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
            let res = await this.fetchContactProfiles(query)

            if(!res.status) {
            }
            if(res.status) {
              this.contactProfiles = res.data;
            }

          } catch (err) {
            if(!err.response) {
              this.showError('Something is wrong. Check your connectivity!!')
            }
            if(err.response) {
              this.showError(err.response.message)
            }
          } finally {
            this.loading = false
          }
        },
        async getBusinesses(){
          try {
            let companyId = this.$route.params.companyId
            let res = await this.fetchBusinessList('?company_id=' + companyId)

            if(!res.status) {
            }
            if(res.status) {
              this.businesses = res.data;
            }

          } catch (err) {
            if(!err.response) {
              this.showError('Something is wrong. Check your connectivity!!')
            }
            if(err.response) {
              this.showError(err.response.message)
            }
          } finally {
            this.loading = false
          }

        },
        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if(! withoutDate) {
                        this.journal.date = new Date().toISOString().split('T')[0]
                    }
                    let query = this.voucherQuery;
                    let voucher = await generateVoucherNumber(query);
                    this.journal.voucher_prefix = voucher.prefix;
                    this.journal.voucher_serial = voucher.serial;
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },
        goToList() {
            this.$router.push({name: 'journal-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        onClose(index) {
            this.journal.ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.journal.ledgers.push({
                account_head_id: 10,
                debit: '',
                credit: '',
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        getFormData () {
            let formData = new FormData();
            Object.keys(this.journal).forEach(i => {
                if(i !== 'ledgers' && i !== 'id' && i !== 'status' && i !== 'business_id' && i !== 'contact_profile_id') {
                    formData.append(i, this.journal[i])
                }
            })
            if(this.journal.business_id) formData.append('business_id', this.journal.business_id)
            if(this.journal.contact_profile_id) formData.append('contact_profile_id', this.journal.contact_profile_id)
            let ledgers = this.journal.ledgers.map((ledger) => {
                ledger.contact_profile_id = this.journal.contact_profile_id;
                ledger.business_id = this.journal.business_id;
                return ledger
            })
            formData.append('general_ledgers', JSON.stringify(ledgers))
            formData.append("_method", "patch")
            return formData;
        },
        async saveJournal () {//editJournal
            this.concatVoucher();
            this.journal.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            try {
                this.loading = true
                let res = await this.editJournal(this.journal.id, data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    // this.resetForm()
                    setTimeout(() => {this.goToList()},1500)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
                if(err.response.data.data && err.response.data.data.show_modal) {
                    this.$refs.voucherValidationModal.openModal(err.response?.data.message)
                }
            } finally {
                this.loading = false
            }
        },
        onChangeDate (e) {
            this.journal.date = e.target.value
        }
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();

        const {
            fetchAccountHeads,
            editJournal,
            fetchJournal,
            loading
        } = handleJournal()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchBusinessList,
        } = handleCBusinesses()

        return {
            fetchAccountHeads,
            fetchContactProfiles,
            fetchBusinessList,
            editJournal,
            fetchJournal,
            decimalFormat,
            loading,
            showError,
            showSuccess
        }
    },

    async mounted() {
        await this.getAccountHeads()
        await this.getBusinesses()
        await this.getContactProfiles()
        await this.getJournal()
    
        let voucher = editVoucherNumber(this.journal.voucher_no);
        this.journal.voucher_prefix = voucher.prefix;
        this.journal.voucher_serial = voucher.serial;
    }
}
</script>

<style scoped>
.label-min-width {
        min-width: 80px
    }
</style>